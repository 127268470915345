import styled from 'styled-components';

const Container = styled.div`
  background: ${({ backgroundColor }) => `var(${backgroundColor})`};

  .dynamic-wire-container {
    max-width: 1196px;
    margin: 0 auto;
    height: 475px;
    box-shadow: 0px 3px 10px rgb(11 26 33 / 8%);
    position: relative;
  }

  a {
    font-weight: var(--font-weight-800);
    font-size: var(--font-size-p);
  }

  .search-icon {
    position: absolute;
    right: 0;
    top: -50px;
  }

  .image {
    max-height: 100%;
  }

  img {
    object-fit: cover;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .styles-container,
  .website-image,
  .gatsby-image-wrapper {
    height: 100%;
  }

  .copy {
    padding: 30px;
    background: var(--white);
  }

  .headline {
    color: var(--darkest);
  }

  .link {
    color: var(--titan-blue-3);
    position: absolute;
    bottom: 25px;
  }

  @media (max-width: 1100px) {
    .body {
      font-size: var(--font-size-p);
    }
  }

  @media (min-width: 576px) and (max-width: 900px) {
    .image,
    .copy {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  @media (max-width: 575px) {
    .dynamic-wire-container {
      height: 100%;
    }

    .headline {
      display: none;
    }

    .copy {
      padding: 30px 0 60px 0;
      background: transparent;

      a,
      p,
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: var(--white);
      }
    }

    svg {
      stroke: var(--white);
    }

    .search-icon {
      top: -40px;
    }
  }
`;

export { Container };
