import React, { useState } from 'react';
import { ArrowRightIcon, ArrowRightHoverIcon } from 'components/Brand/Icons';
import HyperLink from 'components/Core/HyperLink';

import { Container } from './styles';

const HoverableHyperLink = ({
  className,
  hoverIcon,
  href,
  icon,
  iconColor,
  text,
  ctaPosition,
  ctaText,
  ...otherProps
}) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <Container
      iconColor={iconColor}
      className={className}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      {...otherProps}
    >
      <LinkOrTextComponent href={href} ctaPosition={ctaPosition} ctaText={ctaText}>
        {text}
        {isHovering ? hoverIcon : icon}
      </LinkOrTextComponent>
    </Container>
  );
};

export default HoverableHyperLink;

HoverableHyperLink.defaultProps = {
  icon: <ArrowRightIcon />,
  hoverIcon: <ArrowRightHoverIcon />,
};

const LinkOrTextComponent = ({ children, href, ctaPosition, ctaText }) =>
  href ? (
    <HyperLink href={href} ctaPosition={ctaPosition} ctaText={ctaText}>
      {children}
    </HyperLink>
  ) : (
    children
  );
