import React from 'react';
import { truncateText } from 'utils/text';
import { colorTypeHelper } from 'utils/colorUtility';
import { STIcon } from 'components/Brand/Icons/STIcon';
import { Col, Row } from 'components/Core/Grid';
import { getAllToolboxCardData } from 'components/Page/Toolbox/utils';
import { SectionLayout } from 'components/Sections/SectionLayout';
import WebsiteImage from 'components/WebsiteImage';
import HyperLink from 'components/Core/HyperLink';
import HoverableHyperLink from 'components/Core/HyperLink/HoverableHyperLink';

import { Container } from './styles';

const DynamicWire = ({ callToAction, includedPost, section, query }) => {
  const post = includedPost || query?.executedQuery;
  const searchLink = callToAction?.[0]?.url;
  const backgroundColor = section?.styles?.backgroundColor;

  const { imgSrc, imgAlt, kicker, title, excerpt, link } = getAllToolboxCardData(
    post?.length ? post[0] : post,
  );

  return (
    <Container backgroundColor={colorTypeHelper[backgroundColor]}>
      <SectionLayout>
        <HyperLink href={link}>
          <Row className="dynamic-wire-container">
            {searchLink && (
              <HyperLink href={searchLink}>
                <STIcon type="icon-search" className="search-icon" fill="#fff" />
              </HyperLink>
            )}
            <Col xs={24} sm={16} className="image">
              <WebsiteImage image={imgSrc} alt={imgAlt} />
            </Col>
            <Col xs={24} sm={8} className="copy">
              <p className="headline">{kicker}</p>
              <h3>{title}</h3>
              <p className="font-md body">{truncateText(excerpt, 150)}</p>
              <HoverableHyperLink className="link" href={link} text="Learn More" />
            </Col>
          </Row>
        </HyperLink>
      </SectionLayout>
    </Container>
  );
};

export default DynamicWire;
