import styled from 'styled-components';

const Container = styled.span`
  svg {
    stroke: ${({ iconColor }) => iconColor};
    margin-left: 8px;
  }
`;

export { Container };
